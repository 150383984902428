import * as C  from './styles';
const code = require ('./code.mp4');

export const VideoBackgroud = () => {
    return (

        <C.Fundo >
            <video loop autoPlay muted>
            <source src={code} type="video/mp4" />
            <source src={code} type="video/ogg" />
            Your browser does not support the video tag.
            </video>
        </C.Fundo>
    )
}