import * as C from './styles';
import { Tbase } from '../../types/Tbase';

export const PortifolioItem = ({ url, thumbnail, title }: Tbase) => {
    
    const handleSubmit = () => {
        window.open(url)
    }
    
    return (
        <C.Container>
            <img src={thumbnail} alt={title} />
            {title}
            <button onClick={handleSubmit}>Ver</button>
        </C.Container>
    );
}