import styled from "styled-components";


export const Fundo = styled.div`
width: 100vw;
height: 100vh;
object-fit: cover;
position: fixed;
left: 0;
right: 0;
top: 0;
bottom: 0;
z-index: -1;



@media (max-width: 600px) {
    display: none;  
}
  
`;