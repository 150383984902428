import { Home } from './pages/Home';
import * as C from './App.styles';



const App = () => {

  return (
    <C.Container>
      <Home />
    </C.Container>




  );
}

export default App;


