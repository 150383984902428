import axios from 'axios';

let BASE = "https://api.dcdev.site/portifolio/getall.php";

const API = {
    getPortifolio: async () => {    
        const res = await axios.get(BASE);        
        const json = res.data;        
        return json;
    }

    

};

export default API;