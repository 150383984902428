import styled from "styled-components";

export const Container = styled.div`    
    top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;    

  @media(max-width: 720px){
    background-color: #000000;
}  
`;

export const Area = styled.div`
margin: auto;
max-width: 980px;
padding: 30px 0;
z-index:1;

@media(max-width: 720px){
    padding: 5px;
}
`;

export const Header = styled.h1`
margin: 0;
padding: 0;
text-align: center;
margin-bottom: 30px;
`;

export const ScreenWarning = styled.div`
text-align: center;
.emoji {
    font-size: 50px;
    margin-bottom: 20px;
}
`;

export const PhotoList = styled.div`
display: grid;
grid-template-columns: repeat(4, 1fr);
gap: 10px;

@media(max-width: 600px){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
}
`;

export const UploadForm = styled.form`
background-color: #3D3F43;
padding: 15px;
border-radius: 10px;
margin-bottom: 30px;

input[type=submit]{
    background-color: #756DF4;
    border: 0;
    color: #fff;
    padding: 8px 16px;
    font-size: 15px;
    border-radius: 10px;
    margin: 0 20px;
    cursor: pointer;

    &:hover{
        opacity: .9;
    }
}`;

export const Linkedin = styled.div`
margin-top: 30px;
text-align: center;

img {
    max-width: 150px;
}
`;