import React, { useState, useEffect } from 'react';
import * as C from './styles';
import { Tbase } from '../../types/Tbase';
import { PortifolioItem } from '../../Components/PortifolioItem';
import { VideoBackgroud } from '../../Components/VideoBackgroud';
import Dbasejson from '../../libs/dbaseJson.json';
import API from '../../api';

let LinkedinImg = require( '../../assets/images/linkedin.jpeg');


export const Home = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<Tbase[]>([]);

  useEffect(() => {


    setLoading(true);
    const getPortifolio = async () => {
      const json = await API.getPortifolio();
      
      if (json.error == '') {
        console.log(json.result);
        setList(json.result);
        
      }
      
    };
    
    setLoading(false);
    getPortifolio();
  }, []);


  return (
    <>
      <VideoBackgroud />
      <C.Container>
        <C.Area>
          <C.Header>Portifolio - Daniel Carro - Full Stack Developer</C.Header>
          {loading &&
            <C.ScreenWarning>
              <div className='emoji'>✋</div>
              <div>carregando...</div>
            </C.ScreenWarning>
          }
          {!loading && list.length > 0 &&
            <C.PhotoList>
              {list.map((item, index) => (
                <PortifolioItem
                  key={index}
                  url={item.url}
                  title={item.title}
                  thumbnail={item.thumbnail}
                />
              ))}
            </C.PhotoList>
          }
          {!loading && list.length === 0 &&
            <C.ScreenWarning>
              <div className='emoji'>😲</div>
              <div>Não há fotos cadastradas.</div>
            </C.ScreenWarning>
          }
          <C.Linkedin>
            <a href='https://www.linkedin.com/in/daniel-carro-061160245/' target="_blank">
              <img src={LinkedinImg} alt="" />
            </a>
          </C.Linkedin>
        </C.Area>
      </C.Container>
    </>
  );
}


